import { GenericDataApi } from '@/lib/data/GenericDataApi';
import {
    CreateBlastPacketCommand,
    GetBlastPacketQueryResponse,
    UpdateBlastPacketCommand,
    DeleteBlastPacketCommand,
    GetBlastPacketProgressQueryResponse,
    PlanWorkCommand,
    GetBlastPacketsForShiftWindowQueryResponse,
    GetBlastPacketRingStatesForShiftWindowQueryResponse,
    ImportValidationMessageViewModel,
    GetBlastPacketRingStatesForWeekWindowQueryResponse,
    GetBlastPacketsResponse,
    GetBlastPacketSuggestedWorkQueryResponse, MoveRingsToNewBlastPacketCommand, MoveRingsToExistingBlastPacketCommand
} from '@/models/api';
import { Dayjs } from 'dayjs';
import AppHttp from '../AppHttp';
import { LockTag } from '@/models/client/client-row';


export interface ImportedBlastPacketTarget {
    displayOrder: string,
    taskName: string,
    target: number,
    ratePerHour: number | null | undefined
}

export interface ImportedBlastPacketRing {
    blastPacketRingId: string;
    locationRingId: string;
    ringName: string;
    cycleId: string;
    cycleName: string;
    targets: ImportedBlastPacketTarget[],
    validationMessages: ImportValidationMessageViewModel[];
}

export interface ImportedBlastPacket {
    levelId: string,
    levelName: string,
    locationId: string,
    locationName: string,
    blastPacketId: string,
    blastPacketName: string,
    displayOrder: number,
    fireTaskTypeName: string,
    bogTarget: ImportedBlastPacketTarget,
    rings: ImportedBlastPacketRing[]
}



export interface ValidationResult {
    isValid: boolean;
    validationMessages: ImportValidationMessageViewModel[];
    validatedImportedBlastPackets: ImportedBlastPacket[]
}

export interface UploadResult {
    status: number;
    message: string;
}


export class BlastPackets extends GenericDataApi<any> {
    constructor() {
        super('BlastPackets');
    }

    public async createBlastPacket(command: CreateBlastPacketCommand) {
        await AppHttp.post(`${this.resource}/Create`, command);
    }

    public async updateBlastPacket(command: UpdateBlastPacketCommand) {
        await AppHttp.post(`${this.resource}/Update`, command);
    }

    public async deleteBlastPacket(command: DeleteBlastPacketCommand) {
        await AppHttp.delete(`${this.resource}/Delete`, command);
    }

    public async moveRingsToNewBlastPacket(command: MoveRingsToNewBlastPacketCommand): Promise<void> {
        await AppHttp.post(`${this.resource}/Ring/Move/New`, command);
    }

    public async moveRingsToExistingBlastPacket(command: MoveRingsToExistingBlastPacketCommand): Promise<void> {
        await AppHttp.post(`${this.resource}/Ring/Move/Existing`, command);
    }

    public async getSuggestedWork(weekId: string, locationId: string, ringTargetId: string, shiftStart: string, requestedStartTime: string): Promise<GetBlastPacketSuggestedWorkQueryResponse> {
        const params = {
            departmentWeekId: weekId,
            blastPacketRingTargetId: ringTargetId,
            shiftStartTime: shiftStart,
            requestedStartTime: requestedStartTime,
            locationId: locationId
        } as any;

        const response = await AppHttp.get(
            `${this.resource}/SuggestedWork?${new URLSearchParams(params).toString()}`
        );

        const data = response ? response.data : null;
        return data;
    }

    public async planWork(weekId: string, locationId: string, ringTargetId: string, startTime: Dayjs, initialStartTime: Dayjs, quantity: number, durationMinutes: number, tags: LockTag) {
        const command: PlanWorkCommand = {
            _type: 'PlanWorkCommand',
            weekId: weekId,
            blastPacketRingTargetId: ringTargetId,
            startTime: startTime.toDate(),
            initialStartTime: initialStartTime.toDate(),
            quantity: quantity,
            durationMinutes: durationMinutes,
            locationId: locationId,
            currentPlanningTag: tags.current,
            nextPlanningTag: tags.next
        };

        await AppHttp.post(`${this.resource}/PlanWork`, command);
    }

    public async getByDepartment(departmentId: string): Promise<GetBlastPacketsResponse> {
        const response = await AppHttp.get(`${this.resource}/GetForDepartment?departmentId=${departmentId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async getByDepartmentWeek(departmentId: string, departmentWeekId: string): Promise<GetBlastPacketsResponse> {
        const response = await AppHttp.get(`${this.resource}/GetForDepartmentWeek?departmentId=${departmentId}&departmentWeekId=${departmentWeekId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async getByStope(departmentId: string, stopeLocationId: string): Promise<GetBlastPacketsResponse> {
        const response = await AppHttp.get(`${this.resource}/GetForStope?departmentId=${departmentId}&stopeLocationId=${stopeLocationId}`);

        const data = response ? response.data : null;
        return data;
    }

    public async getWindowByShift(mineAreaId: string,
                                  departmentId: string,
                                  date: string,
                                  number: string,
                                  offsetPrior: number,
                                  offsetFollowing: number): Promise<GetBlastPacketsForShiftWindowQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/GetWindowForShift?mineAreaId=${mineAreaId}&departmentId=${departmentId}&date=${date}&number=${number}&offsetPrior=${offsetPrior}&offsetFollowing=${offsetFollowing}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async getStatesWindowByShift(mineAreaId: string,
                                        departmentId: string,
                                        date: string,
                                        number: string,
                                        offsetPrior: number,
                                        offsetFollowing: number): Promise<GetBlastPacketRingStatesForShiftWindowQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/GetStatesWindowForShift?mineAreaId=${mineAreaId}&departmentId=${departmentId}&date=${date}&number=${number}&offsetPrior=${offsetPrior}&offsetFollowing=${offsetFollowing}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async getStatesWindowByWeek(mineAreaId: string,
                                       departmentId: string,
                                       planId: string): Promise<GetBlastPacketRingStatesForWeekWindowQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/GetStatesWindowForWeek?mineAreaId=${mineAreaId}&departmentId=${departmentId}&planId=${planId}`);

        const data = response ? response.data : null;
        if(data == null) throw Error('No response data');
        return data;
    }

    public async getById(blastPacketId: string): Promise<GetBlastPacketQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/Get?id=${blastPacketId}`);

        const data = response ? response.data : null;
        if (data == null) throw Error('No response data');
        return data;
    }

    public async getProgress(plannedTaskId: string, shiftId?: string | null): Promise<GetBlastPacketProgressQueryResponse> {
        let url = `${this.resource}/progress?plannedTaskId=${plannedTaskId}`;

        if(shiftId)
            url += `&shiftId=${shiftId}`;

        const response = await AppHttp.get(url);

        const data = response ? response.data : null;
        if (data == null) throw 'No response data';
        return data;
    }

    public async upload(departmentId: string, blastPackets: File) : Promise<ValidationResult | null> {
        const response = await AppHttp.postWithFiles(`${this.resource}/upload/${departmentId}`, [], [blastPackets]);
        const data: ValidationResult | null = response ? response.data['file'] : null;
        return data;
    }

    public async import(departmentId: string, blastPackets: ValidationResult) : Promise<UploadResult | null> {
        const response = await AppHttp.post(`${this.resource}/import/${departmentId}`, blastPackets);
        const data: UploadResult | null = response ? response.data : null;
        return data;
    }
}

export default new BlastPackets();
