import { render, staticRenderFns } from "./FilteringAuditEventViewer.vue?vue&type=template&id=77960d87&scoped=true&"
import script from "./FilteringAuditEventViewer.vue?vue&type=script&lang=ts&"
export * from "./FilteringAuditEventViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77960d87",
  null
  
)

export default component.exports