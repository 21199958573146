import AppHttp from '@/lib/AppHttp';
import { UpdateActualsCommand } from '@/models/api/Commands/update-actuals-command';
import {
    GetActualsDefinitionHasDataQueryResponse, GetActualsGroupHasDataQueryResponse,
    UpsertActualsDefinitionCommand,
    UpsertActualsGroupCommand
} from '@/models/api';

export class Actuals {
    private resource: string = 'Actuals';
    
    public async getFilterOptionsForDepartmentShiftId(departmentId: string, shiftId: string) {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/GetFilterOptionsForDepartmentShift?departmentShiftId=${shiftId}`);

        if (!response || !response.data)
            return [];
        else
            return response.data;
    }

    public async upsertDefinition(command: UpsertActualsDefinitionCommand) {
        const response = await AppHttp.put(`Departments/${command.departmentId!}/${this.resource}/definitions`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async upsertGroup(command: UpsertActualsGroupCommand) {        
        const response = await AppHttp.put(`Departments/${command.departmentId}/${this.resource}/groups`, command);
                
        const data = response ? response.data : null;
        return data;
    }

    public async getGroups(departmentId:string, taskTypeIds: string[]): Promise<any[]> {
        const response = await AppHttp.post(`Departments/${departmentId}/${this.resource}/Groups`, {
            departmentId: departmentId,
            onlyForTaskTypeIds: taskTypeIds
        });

        if (!response || !response.data)
            return [];
        else
            return response.data.actualsGroups;
    }
    
    public async deleteActuals(departmentId: string, actualsId: string) {
        const response = await AppHttp.delete(`Departments/${departmentId}/${this.resource}/${actualsId}`);
        return response;
    }
    
    public async deleteDefinition(departmentId: string, definitionId: string) {
        const response = await AppHttp.delete(`Departments/${departmentId}/${this.resource}/Definitions/${definitionId}`);
        return response;
    }

    public async deleteGroup(departmentId: string, groupId: string) {
        const response = await AppHttp.delete(`Departments/${departmentId}/${this.resource}/Groups/${groupId}`);
        return response;
    }
    
    public async getDefinitions(departmentId: string): Promise<any[]> {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/Definitions`);
        
        if (!response || !response.data)
            return [];
        else
            return response.data.actualsDefinitions;
    }

    public async definitionHasData(departmentId: string, definitionId: string): Promise<GetActualsDefinitionHasDataQueryResponse> {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/Definitions/${definitionId}/HasData`);

        return response ? response.data : null;
    }

    public async groupHasData(departmentId: string, groupId: string): Promise<GetActualsGroupHasDataQueryResponse> {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/Groups/${groupId}/HasData`);

        return response ? response.data : null;
    }
    
    public async downloadCsv(departmentId: string, startDate: any, endDate: any) {
        const response = await AppHttp.post(
            `Departments/${departmentId}/${this.resource}/DownloadActualsCsv`,
            {
                departmentId,
                startDate,
                endDate
            });
        
        return response;
    }
        
    public async getGroupsForDepartmentShiftId(departmentId:string, shiftId: string): Promise<any[]> {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/GetGroupsForDepartmentShift?departmentShiftId=${shiftId}`);

        if (!response || !response.data)
            return [];
        else
            return response.data.actualsGroups;
    }
    
    public async getActualsDataForDepartmentShiftId(
        departmentId: string,
        shiftId: string,
        taskTypeId?: string,
        operatorId?: string,
        equipmentId?: string,
        locationId?: string
    ): Promise<any[]> {        
        let url = `Departments/${departmentId}/${this.resource}/GetForDepartmentShift?departmentShiftId=${shiftId}`;
        
        if (taskTypeId)
            url += `&taskTypeId=${taskTypeId}`;
        if (operatorId)
            url += `&operatorId=${operatorId}`;
        if (equipmentId)
            url += `&equipmentId=${equipmentId}`;
        if (locationId)
            url += `&locationId=${locationId}`;
        
        const response = await AppHttp.get(url);
        
        if (!response || !response.data)
            return [];
        else 
            return response.data.actualsResponses;
    }

    public async update(departmentId: string, shiftId: string, commands: UpdateActualsCommand[]) {
        const response = await AppHttp.put(`Departments/${departmentId}/${this.resource}/Shift/${shiftId}`, commands);
        const data = response ? response.data : null;
        return data;
    }

    public async populateFromShiftPlan(departmentId: string, shiftId: string) {
        await AppHttp.post(`Departments/${departmentId}/${this.resource}/Shift/${shiftId}/Populate`, null);
    }

    public async exportDefinitions(departmentId: string) {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/Definitions/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async importDefinitions(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`Departments/${departmentId}/${this.resource}/Definitions/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async exportGroups(departmentId: string) {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/Groups/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async importGroups(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`Departments/${departmentId}/${this.resource}/Groups/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }
}

export default new Actuals();