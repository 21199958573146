import Vue from 'vue';
import Terms from '@/lib/Terms';

Vue.prototype.$term = async (term: string) => await Terms.term(term);
Vue.prototype.$plural = async (term: string, count?: number) => await Terms.termPlural(term, count);
Vue.prototype.$termSync = (term: string) => Terms.termSync(term);
Vue.prototype.$pluralSync = (term: string, count?: number) => Terms.termPluralSync(term, count);

// 2. Specify a file with the types you want to augment
//    Vue has the constructor type in types/vue.d.ts
declare module 'vue/types/vue' {
    // 3. Declare augmentation for Vue
    interface Vue {
        $termSync: (term: string) => string;
        $pluralSync: (term: string, count?: number) => string;
        $term: (term: string) => Promise<string>;
        $plural: (term: string, count?: number) => Promise<string>;
    }
}

Vue.filter('term', (term: string, count?: number) => {
    if (count == undefined) {
        return Terms.termSync(term);
    } else {
        return Terms.termPluralSync(term, count);
    }
});

Vue.filter('termPlural', (term: string) =>{
    return Terms.termPluralSync(term, 2);
})
