import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import wait from './plugins/wait';
import '@/components/DragDrop/plugin.ts';
import './plugins/shared';
import './plugins/dates';
import './plugins/nav';
import './plugins/terms';
import './plugins/enums';
import './plugins/numbers';
import './plugins/strings';
import { i18n } from './plugins/i18n';
import Validation from './plugins/validation';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@/variables.scss';
import Config from '@/lib/Config';
import PWA from '@/lib/PWA';
import RequestHandler from '@/lib/api/RequestHandler';
import AuthService from '@/lib/Auth';
import { createPinia, PiniaVuePlugin } from 'pinia';

AuthService.init();
RequestHandler.init();
PWA.init();

import dayjs from 'dayjs';

import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore.default);

import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter.default);

import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc.default);

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

// @ts-ignore
import DatetimePicker from 'vuetify-datetime-picker';
// @ts-ignore
import VueAppInsights from 'vue-application-insights';
// @ts-ignore
import VueDraggableResizable from 'vue-draggable-resizable';

Vue.component('vue-draggable-resizable', VueDraggableResizable);
import 'mobile-drag-drop/default.css';
import Logging from '@/lib/Logging';
import { focusElement } from '@/directives/directives';
require('typeface-khula');

Vue.config.productionTip = false;
Vue.config.errorHandler=(err: Error, instance: Vue, info: string)=>{
    Logging.error(err);
}
Vue.use(Validation);
Vue.use(DatetimePicker);
Vue.use(PiniaVuePlugin);

Vue.directive('focus', focusElement);

if (Config.AppInsightsKey && Config.AppInsightsKey != 'none') {
    Vue.use(VueAppInsights, {
        id: Config.AppInsightsKey,
    });
}

const pinia = createPinia();

new Vue({
    i18n,
    router,
    vuetify,
    wait,
    pinia,
    render: (h: any) => h(App),
} as any).$mount('#app');
