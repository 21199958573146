import { isEosTaskType } from '@/lib/TaskType';
import { DelayTypes, TaskTypeViewModel, TaskClasses } from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';
import WeekTask from '@/models/client/week-task';
import { TimeBlock, TimePeriod } from '@/models/client/time-block';
import { TaskErrorType } from '@/models/client/types/task-error-type';
import { Dayjs } from 'dayjs';
import { BlankStringToNull, FormatWithPrecision } from '@/lib/services/Utils';
import ClientTask from '@/models/client/client-task';
import { ClientTaskWarning } from '@/models/client/client-task-warning';
import { ClientTaskError } from '@/models/client/client-task-error';
import { ClientTaskIssueType } from '@/models/client/client-task-issues';
import { DefaultTaskBackgroundColour, DefaultTaskForegroundColour } from '@/lib/services/TaskType';
import { TIME_UNIT_MINUTES } from '@/lib/Constants';

export enum TaskLengthClassification {
    Normal = 0,
    Short = 1,
    Long = 2
}

export enum TaskInsetDisplayType {
    None = 0,
    Icon = 1,
    SmallCard = 2,
    LargeCard = 3,
}

export enum TaskNotificationIconDisplayType {
    None = 0,
    CornerIcon = 1,
    CentralIcon = 2,
}

export interface TaskDisplayInstructions {
    centralDisplayText: string,
    insetDisplayType: TaskInsetDisplayType,
    notificationIconDisplayType: TaskNotificationIconDisplayType
}

export function IsZeroDelay(task: ClientTaskModel) {
    return task.startTime.isSame(task.endTime) && IsFloatDelay(task);
}

export function IsFloatDelay(task: { taskType: TaskTypeViewModel }) {
    if (
        task.taskType?.isDelay &&
        (task.taskType?.delayType === DelayTypes.Float ||
            // @ts-ignore
            task.taskType?.delayType === 1 ||
            // @ts-ignore
            task.taskType?.delayType === 'Float')
    ) {
        return true;
    }

    return false;
}

const taskLengthClassificationCutoffs = [
    {
        lettersInName: 5,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 172,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 257,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    },
    {
        lettersInName: 10,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 257,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 385,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    },
    {
        lettersInName: 20,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 343,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 470,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    },
    {
        lettersInName: 30,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 428,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 557,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    },
    {
        lettersInName: 40,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 557,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 685,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    },
    {
        lettersInName: null,
        cutoffs: [
            {
                classification: TaskLengthClassification.Short,
                cutoff: 685,
            },
            {
                classification: TaskLengthClassification.Normal,
                cutoff: 813,
            },
            {
                classification: TaskLengthClassification.Long,
                cutoff: null,
            }
        ]
    }
]

export function GetTaskLengthClassification(task: ClientTaskModel | WeekTask, taskWidthPixels: number): TaskLengthClassification {
    return getTaskLengthClassificationFromText(task.taskType.name, taskWidthPixels);
}

function getTaskLengthClassificationFromText(taskText: string | null, pixelWidth: number): TaskLengthClassification {
    const lettersInTaskText = taskText?.length ?? 0;

    return taskLengthClassificationCutoffs
        .find(x=>x.lettersInName === null || lettersInTaskText <= x.lettersInName)!.cutoffs
        .find(x=> x.cutoff === null || pixelWidth <= x.cutoff)!.classification;

}

export function IsDelay(task: {  taskType: TaskTypeViewModel }): boolean {
    return task.taskType.isDelay;
}

export function isSystemTask(task: {  taskType: TaskTypeViewModel }) {
    return task.taskType && task.taskType.taskClass !== TaskClasses.UserDefined;
}

export function isFireTask(task: { taskType: TaskTypeViewModel }) {
    return task.taskType && task.taskType.taskClass === TaskClasses.Fire;
}

export function isDrillTask(task: { taskType: TaskTypeViewModel }) {
    return task.taskType && task.taskType.taskClass === TaskClasses.Drilling;
}

export function isCharge(task: {  taskType: TaskTypeViewModel }) {
    return task.taskType && task.taskType.taskClass === TaskClasses.Charge;
}

export function isBoggingTask(task: { taskType: TaskTypeViewModel }) {
    return task.taskType && (task.taskType.taskClass === TaskClasses.ConventionalBogging || task.taskType.taskClass === TaskClasses.RemoteBogging);
}

export function GetTaskBackgroundColour(task: { taskType: { backgroundColour: string | null } }, fallbackColour: string = DefaultTaskBackgroundColour) {
    return task.taskType.backgroundColour ?? fallbackColour;
}

export function GetTaskForegroundColour(task: { taskType: { foregroundColour: string | null } }, fallbackColour: string = DefaultTaskForegroundColour) {
    return task.taskType.foregroundColour ?? fallbackColour;
}

export function IsMoveBlocking<T extends TimeBlock>(task: T) {
    return task.isFloatable !== true;
}

export function IsRelatedToTask<T extends TimeBlock>(task: T, otherTask: T | null) {
    if (otherTask === null || otherTask === undefined) return false;

    if (task.correlationId === null || task.correlationId === undefined) return false;

    return task.correlationId === otherTask.correlationId;
}

export function SetTaskLockedInPast(task: ClientTaskModel, isLocked: boolean) {
    task.lockedInPast = isLocked;

    if(isLocked){
        task.preventMoving = true;
        task.preventResizing = true;
    } else {
        RestoreTaskLocksForTask(task);
    }
}

export function RestoreTaskLocksForTask(task: ClientTaskModel) {
    task.preventMoving = false;
    task.preventResizing = !task.canResize;
}

export function SetWeekTaskLockedInPast(task: WeekTask, isLocked: boolean) {
    task.lockedInPast = isLocked;

    if(isLocked){
        task.preventMoving = true;
        task.preventResizing = true;
        task.preventChangingQuantity = true;
    } else {
        RestoreTaskLocksForWeeklyTask(task);
    }
}

export function RestoreTaskLocksForWeeklyTask(task: WeekTask) {
    task.preventMoving = !task.canMove;
    task.preventResizing = !task.canResize;
    task.preventChangingQuantity = !task.canUpdateQuantity;
}

export function GetTaskName(task: { taskName: string | null; taskType: { name: string | null, abbrName: string | null | undefined } }, abbreviate: boolean = false): string {
    if (!task) return '';
    if (shouldUseTaskOverriddenName(task)) return task.taskName!;

    if (abbreviate) return BlankStringToNull(task.taskType?.abbrName) ?? task.taskType?.name ?? '';
    else return task.taskType?.name ?? '';
}

function shouldUseTaskOverriddenName(task: { taskName: string | null; taskType: { name: string | null }}) {
    return (task.taskName && task.taskName !== task.taskType.name);
}

export function GetTaskDisplayInstructions(task: ClientTaskModel | WeekTask, taskWidthPixels: number, hideTaskNameIfDelay: boolean): TaskDisplayInstructions {
    const displayText = GetTaskDisplayWithContext(task, taskWidthPixels, hideTaskNameIfDelay);

    const taskLengthClassificationForIcons = getTaskLengthClassificationFromText(displayText, taskWidthPixels);

    let equipmentDisplayType = TaskInsetDisplayType.SmallCard;
    let notificationIconDisplayType = TaskNotificationIconDisplayType.CentralIcon;

    if(taskLengthClassificationForIcons === TaskLengthClassification.Short){
        equipmentDisplayType = TaskInsetDisplayType.Icon;
        notificationIconDisplayType = TaskNotificationIconDisplayType.CornerIcon;
    } else if(taskLengthClassificationForIcons === TaskLengthClassification.Long) {
        equipmentDisplayType = TaskInsetDisplayType.LargeCard;
    }

    return {
        centralDisplayText: displayText,
        insetDisplayType: equipmentDisplayType,
        notificationIconDisplayType: notificationIconDisplayType
    };
}

export function GetTaskDisplayWithContext(task: ClientTaskModel | WeekTask, taskWidthPixels: number, hideTaskNameIfDelay: boolean) {
    const taskLengthClassification = GetTaskLengthClassification(task, taskWidthPixels);

    if(task.blastPacketRingTargetId == null)
        return GetTaskDisplayText(task, '', taskLengthClassification);

    if (task.taskType?.isDelay && hideTaskNameIfDelay) return '';
    return GetTaskDisplayText(task, task.blastPacketDisplayInformation ?? '', taskLengthClassification);
}

export function GetTaskDisplayText(task: { taskName: string | null; overriddenLocationName?: string | null; durationMinutes: number; taskType: { name: string | null, abbrName: string | null | undefined, isDelay: boolean } }, prependInformation: string = '', taskLengthClassification: TaskLengthClassification = TaskLengthClassification.Normal): string {
    if (task.taskType?.isDelay && task.durationMinutes < 3 * TIME_UNIT_MINUTES) return '';

    const prependText = prependInformation !== '' && taskLengthClassification !== TaskLengthClassification.Short ? `${prependInformation} - ` : '';

    if (task.overriddenLocationName) return `${prependText}${task.overriddenLocationName}: ${GetTaskName(task, taskLengthClassification === TaskLengthClassification.Short)}`;
    return `${prependText}${GetTaskName(task, taskLengthClassification === TaskLengthClassification.Short)}`;
}

export function AddErrorToTask(task: { errors: ClientTaskError[] }, errorMessage: string, errorType: TaskErrorType, issueType: ClientTaskIssueType) {
    if(task.errors.some(e=>e.message === errorMessage && e.errorType === errorType && e.type === issueType))
        return;

    task.errors = [... task.errors, {
        message: errorMessage,
        errorType: errorType,
        type: issueType
    }];
}

export function AddWarningToTask(
    task: { warnings: ClientTaskWarning[]; },
    warning: ClientTaskWarning
): boolean {
    if(task.warnings.some(w=>w.type===warning.type && (w.warningCategoryId === warning.warningCategoryId || w.message === warning.message)))
        return false;

    task.warnings.push(warning);
    return true;
}

export function MoveTaskBy<T extends TimePeriod>(task: T, minutes: number) {
    task.startTime = task.startTime.add(minutes, 'minutes');
    task.endTime = task.endTime.add(minutes, 'minutes');
}

export function MoveTaskTo<T extends TimeBlock>(task: T, startTime: Dayjs) {
    const durationMinutes = task.endTime.diff(task.startTime, 'minutes');
    task.startTime = startTime;
    task.endTime = startTime.add(durationMinutes, 'minutes');
}

export function ChangeTaskDuration<T extends TimeBlock>(task: T, durationInMinutes: number) {
    if (durationInMinutes < 0) {
        throw new Error(`Cannot set duration to negative value: ${durationInMinutes}`);
    }

    task.durationMinutes = durationInMinutes;
    task.endTime = task.startTime.add(durationInMinutes, 'minutes');
}

export function ChangeTaskQuantity<T extends TimeBlock>(task: T, quantity: number | null, updateRate: boolean = false) {
    if (quantity === null) return;

    if (quantity < 0) {
        throw new Error(`Cannot set quantity to negative value: ${quantity}`);
    }

    //@ts-ignore
    task.quantity = RoundTaskQuantity(quantity);

    if (updateRate) {
        //@ts-ignore
        task.ratePerHour = CalculateRate(task.quantity, task.durationMinutes);
    }
}

export function ChangeTaskRatePerHour<T extends TimeBlock>(task: T, ratePerHour: number | null) {
    if (ratePerHour === null) return;

    if (ratePerHour < 0) {
        throw new Error(`Cannot set quantity to negative value: ${ratePerHour}`);
    }

    //@ts-ignore
    task.ratePerHour = roundTaskRatePerHour(ratePerHour);
}

export function CalculateRateTaskQuantity(task: ClientTaskModel) {
    if (!IsRateTask(task)) throw `Cannot calculate quantity for a non-rate task.`;

    if (task.ratePerHour == null) throw `Cannot calculate quantity for a task without a rate.`;

    task.quantity = CalculateQuantity(task.ratePerHour, task.durationMinutes);
}

export function CalculateRateTaskRate(task: ClientTaskModel | WeekTask) {
    if(!IsRateTask(task)) throw `Cannot calculate rate for a non-rate task.`;

    if(task.quantity == null) throw `Cannot calculate rate for a task without a quantity.`;

    task.ratePerHour = CalculateRate(task.quantity, task.durationMinutes);
}

export function CalculateQuantity(ratePerHour: number, durationMinutes: number, precision: number = 1): number {
    if(ratePerHour <= 0)
        throw new Error('RatePerHour must be a positive number.');

    if(durationMinutes < 0)
        throw new Error('DurationMinutes must be a non-negative number.');

    if(precision < 0)
        throw new Error('Precision must be a non-negative number.');



    const rawResult = (durationMinutes * ratePerHour) / 60;

    return RoundTaskQuantity(rawResult);
}

export function CalculateRate(quantity: number, durationMinutes: number, precision: number = 1): number {
    if(quantity < 0)
        throw new Error('Quantity must be a non-negative number.');

    if(durationMinutes < 0)
        throw new Error('DurationMinutes must be a non-negative number.');

    if(precision < 0)
        throw new Error('Precision must be a non-negative number.');

    const rawResult = (quantity * 60) / durationMinutes;

    return roundTaskRatePerHour(rawResult, precision);
}

export function RoundTaskQuantity(quantity: number, precision: number = 1): number {
    const multiplicationParameter = Math.pow(10, precision);

    return Math.trunc(quantity*multiplicationParameter) / multiplicationParameter;
}

export function FormatTaskQuantity(quantity: number, precision: number = 1): string {
    return FormatWithPrecision(quantity, precision);
}

function roundTaskRatePerHour(ratePerHour: number, precision: number = 1): number {
    const multiplicationParameter = Math.pow(10, precision);

    return Math.trunc(ratePerHour*multiplicationParameter) / multiplicationParameter;
}

export function PushTaskForwardTo<T extends TimeBlock>(task: T, pushTo: Dayjs) {
    if (pushTo.isBefore(task.startTime))
        throw new Error(`Push to time ${pushTo.toISOString()} is before task start time ${task.startTime.toISOString()}.`);

    if (task.isFloatable) {
        task.startTime = pushTo;

        if (task.startTime > task.endTime) {
            task.endTime = task.startTime;
        }

        task.durationMinutes = task.endTime.diff(task.startTime, 'minutes');
    } else {
        MoveTaskTo(task, pushTo);
    }
}

export function PushTaskBackwardTo<T extends TimeBlock>(task: T, pushTo: Dayjs, ignoreIfAlreadyBeforePushTo: boolean = false) {
    if (pushTo.isAfter(task.endTime)){
        if(ignoreIfAlreadyBeforePushTo)
            return;

        throw new Error(`Push to time ${pushTo.toISOString()} is after task end time ${task.endTime.toISOString()}.`);
    }


    if (task.isFloatable !== true) return;

    task.endTime = pushTo;

    if (task.startTime > task.endTime) {
        task.startTime = task.endTime;
    }

    task.durationMinutes = task.endTime.diff(task.startTime, 'minutes');
}

export function PushTaskBackwardAtLeastTo(task: ClientTaskModel, pushTo: Dayjs) {
    PushTaskBackwardTo(task, pushTo, true);
}

export function PullTaskBackwardTo<T extends TimeBlock>(task: T, pullTo: Dayjs) {
    if (pullTo.isAfter(task.endTime))
        throw new Error(`Pull to time ${pullTo.toISOString()} is after task end time ${task.endTime.toISOString()}`);

    if (task.isFloatable) {
        task.startTime = pullTo;
        task.durationMinutes = task.endTime.diff(task.startTime, 'minutes');
    } else {
        MoveTaskTo(task, pullTo);
    }
}

export function PullTaskBackwardByBaller<T extends TimeBlock>(task: T, offsetMinutes: number) {
    const pullTo = task.startTime.subtract(offsetMinutes, 'minutes');
    PullTaskBackwardTo(task, pullTo);
}

export function PullTaskForwardTo<T extends TimeBlock>(task: T, pullTo: Dayjs) {
    if (pullTo.isBefore(task.startTime))
        throw new Error(`Pull to time ${pullTo.toISOString()} is before task start time ${task.startTime.toISOString()}`);

    if (task.isFloatable) {
        task.endTime = pullTo;
        task.durationMinutes = task.endTime.diff(task.startTime, 'minutes');
    }
}

export function CanHaveEquipmentAssigned(task: ClientTaskModel): boolean {
    if (task.taskType.isDelay) return false;

    return true;
}

export function GetTaskNameWithPrependedLocation(task: ClientTaskModel | WeekTask) {
    if (task.overriddenLocationName) {
        return `${task.overriddenLocationName}: ${GetTaskName(task)}`;
    }

    return GetTaskName(task);
}

export function GetTaskLocationName(task: ClientTaskModel) {
    return task.overriddenLocationName ?? task.locationName ?? '';
}

export function GetTaskNameWithBlastPacket(task: WeekTask): string {
    return task?.blastPacketName !== '' ? `${GetTaskName(task)} - ${task?.blastPacketName ?? 'n/a'}` : GetTaskName(task);
}

export function IsRateTask(task: ClientTaskModel | WeekTask) {
    if (task.taskType.rateMetric === null || task.taskType.rateMetric === undefined) return false;

    return true;
}

export function TaskIncludesTime<T extends TimePeriod>(task: T, time: Dayjs) {
    return task.startTime.isSameOrBefore(time) && task.endTime.isSameOrAfter(time);
}

export function IncludesExclusiveEnd<T extends TimePeriod>(period: T, time: Dayjs) {
    return period.startTime.isSameOrBefore(time) && period.endTime.isAfter(time);
}

export function TaskStartsDuring<T extends TimeBlock>(task: T, startTime: Dayjs, endTime: Dayjs) {
    return task.startTime.isSameOrAfter(startTime) && task.startTime.isBefore(endTime);
}

export function TimespanContainsTask<T extends TimePeriod>(task: T, startTime: Dayjs, endTime: Dayjs): boolean {
    return startTime.isSameOrBefore(task.startTime) && endTime.isSameOrAfter(task.endTime);
}

export function TaskIntersectsTimespan<T extends TimePeriod>(task: T, startTime: Dayjs, endTime: Dayjs): boolean {
    if (task.startTime.isSameOrBefore(startTime) && task.endTime.isSameOrBefore(startTime)) return false;
    if (task.startTime.isSameOrAfter(endTime) && task.endTime.isSameOrAfter(endTime)) return false;

    return true;
}

export function IsLastWorkpackageItemTask(task: ClientTaskModel, tasks: ClientTaskModel[]): boolean {
    if (IsRateTask(task) === false) return false;

    const lastMatch = tasks
        .slice()
        .reverse()
        .find((x) => task.taskTypeId === x.taskTypeId && task.plannedCycleId === x.plannedCycleId);

    return lastMatch?.id === task.id;
}

export function IsFirstWorkpackageItemTask(task: ClientTaskModel, tasks: ClientTaskModel[]): boolean {
    if (IsRateTask(task) === false) return false;

    const firstMatch = tasks.find((x) => task.taskTypeId === x.taskTypeId && task.plannedCycleId === x.plannedCycleId);
    return firstMatch?.id === task.id;
}

export function IsEosTask(task: ClientTaskModel, departmentEosTaskType: TaskTypeViewModel | null): boolean {
    return isEosTaskType(task.taskType, departmentEosTaskType);
}

export function IsLinkedToBlastPacket(task: ClientTaskModel) : boolean {
    return !(task.blastPacketRingTargetId == undefined || task.blastPacketRingTargetId == null);
}