import { defineStore } from 'pinia';
import dayjs, { Dayjs, UnitTypeLong } from 'dayjs';
import ShiftWindowApi from '@/lib/data/ShiftWindowApi';
import {
    EquipmentDowntimeCodeViewModel,
    EquipmentDowntimeViewModel,
    LocationPlannedHighLevelActualsViewModel,
    UpdateEquipmentDowntimeCommand
} from '@/models/api';
import { ShiftDetails } from '@/lib/stores/ShiftWindowStore';
import { useShiftDetails } from '@/lib/stores/Shift/ShiftDetailsStore';
import Shift from '@/lib/data/Shift';
import EquipmentDowntimeCodes from '@/lib/data/EquipmentDowntimeCodes';

export const useShiftHighLevelActuals = defineStore('shiftHighLevelActuals', {
    state: () => {
        return {
            highLevelActuals: null as null | LocationPlannedHighLevelActualsViewModel[],
            equipmentDowntime: null as null | EquipmentDowntimeViewModel[],
            downtimeCodes: null as null | EquipmentDowntimeCodeViewModel[],
            loading: false
        };
    },
    getters: {
        shiftPredictions(): LocationPlannedHighLevelActualsViewModel[] {
            const shiftStartTime = useShiftDetails().shiftStartTime;
            const shiftEndTime = useShiftDetails().shiftEndTime;

            return this.highLevelActuals?.filter((location) => {
                const locationCompletionTime = dayjs(location.completionTime);
                return locationCompletionTime.isSameOrAfter(shiftStartTime) && locationCompletionTime.isSameOrBefore(shiftEndTime);
            }) ?? [];
        },
        shiftDowntime(): EquipmentDowntimeViewModel[] {
            return this.equipmentDowntime?.filter((downtime) => downtime.shiftId === useShiftDetails().shiftId) ?? [];
        }
    },
    actions: {
        async populateWindow(mineId: string, departmentId: string, date: string, shiftNumber: string, offsetPrior: number, offsetFollowing: number) {
            this.loading = true;
            await this.populateHighLevelActuals(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
            await this.populateEquipmentDowntime(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
            if(this.downtimeCodes == null)
                this.downtimeCodes = await EquipmentDowntimeCodes.getCodes(departmentId);
            this.loading = false;
        },
        async populateHighLevelActuals(mineId: string, departmentId: string, date: string, shiftNumber: string, offsetPrior: number, offsetFollowing: number) {
            const response = await ShiftWindowApi.getHighLevelActualsWindow(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
            this.highLevelActuals = response.locations;
        },
        async populateEquipmentDowntime(mineId: string, departmentId: string, date: string, shiftNumber: string, offsetPrior: number, offsetFollowing: number) {
            const response = await ShiftWindowApi.getEquipmentDowntimeWindow(mineId, departmentId, date, shiftNumber, offsetPrior, offsetFollowing);
            this.equipmentDowntime = response.equipmentDowntime;
        },
        async updateEquipmentDowntime(command: UpdateEquipmentDowntimeCommand){
            await Shift.updateEquipmentDowntime(command);

            // Update the local store
            this.equipmentDowntime = [...this.equipmentDowntime!.filter(x=>!command.toRemove.includes(x.id))
                .map(x=>{
                    const update = command.toUpdate.find(y=>y.id === x.id);

                    if(update != null){
                        return {
                            ...x,
                            equipmentDowntimeCodeId: update.downtimeCode,
                            locationId: update.locationId,
                            locationNameOverride: update.locationOverride,
                            equipmentId: update.equipmentId,
                            equipmentNameOverride: update.equipmentOverride,
                            comment: update.comment,
                            durationMinutes: update.durationMinutes
                        };
                    }

                    return x;
                }), ...command.toUpdate.filter(x=>!this.equipmentDowntime!.map(y=>y.id).includes(x.id)).map(x=>({
                    _type: 'EquipmentDowntimeViewModel',
                    id: x.id,
                    shiftId: useShiftDetails().shiftId,
                    locationId: x.locationId,
                    locationNameOverride: x.locationOverride,
                    equipmentId: x.equipmentId,
                    equipmentNameOverride: x.equipmentOverride,
                    comment: x.comment,
                    durationMinutes: x.durationMinutes,
                    equipmentDowntimeCodeId: x.downtimeCode
            } as EquipmentDowntimeViewModel))];
        }
    }
});