import AppHttp from '@/lib/AppHttp';
import ResourceHttp from '@/lib/ResourceHttp';
import {
    ApplicationAuditEventNamespace,
    CreateEquipmentDowntimeCodeCommand,
    EquipmentDowntimeCodeViewModel, PaginatedHistoricalAuditEventsViewModel, ReorderEquipmentDowntimeCodesCommand,
    UpdateEquipmentDowntimeCodeCommand
} from '@/models/api';
import dayjs from 'dayjs';

export class AuditEvents {
    private resource: string = 'AuditEvents';


    public async getForNamespace(namespace: ApplicationAuditEventNamespace, eventTypeFilter: string | null, fromDate: dayjs.Dayjs | null, toDate: dayjs.Dayjs | null, pageNum: number = 1, pageSize: number = 30): Promise<PaginatedHistoricalAuditEventsViewModel> {
        let params = {
            pageNum,
            pageSize
        } as any;

        if(fromDate != null)
            params = {...params, from: fromDate.format("YYYY-MM-DD")};

        if(toDate != null)
            params = {...params, to: toDate.format("YYYY-MM-DD")};

        if(eventTypeFilter != null)
            params = {...params, eventTypeFilter: eventTypeFilter};

        const response = await AppHttp.get(`${this.resource}/namespace/${namespace.toString()}?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }

    public async getForEntity(entityId: string, pageNum: number = 1, pageSize: number = 30): Promise<PaginatedHistoricalAuditEventsViewModel> {
        const params = {
            pageNum,
            pageSize,
        } as any;

        const response = await AppHttp.get(`${this.resource}/entity/${entityId}?${new URLSearchParams(params).toString()}`);
        const data = response ? response.data : null;
        return data;
    }
}

export default new AuditEvents();