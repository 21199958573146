import { GenericDataApi } from '@/lib/data/GenericDataApi';
import AppHttp from '@/lib/AppHttp';
import Errors from '@/lib/Errors';
import {
    DeleteAdHocTaskCommand,
    UpdateAdHocTaskTimesCommand,
    CreateAdHocTaskCommand,
    UpdateAdHocTaskCommand,
    TaskCommandResponse,
    SplitAdhocTaskCommand,
    ManualPlanWorkCommand, GetManualTaskSuggestedWorkQueryResponse
} from '@/models/api';
import { AxiosError } from 'axios';
import RequestHandler from '../api/RequestHandler';
import { Dayjs } from 'dayjs';

export class PlannedAdHocTasks extends GenericDataApi<any> {
    constructor() {
        super('PlannedAdHocTasks');
    }

    public async createAdHocTask(command: CreateAdHocTaskCommand) {
        const response: any = await RequestHandler.execute('POST', `PlannedAdHocTasks/CreateAdHocTask`, command);
        const data = response ? response.data : null;
        return data;
    }
}

export class PlannedAdHocWeekTasks {
    public async getSuggestedWork(weekId: string, locationId: string, taskTypeId: string, shiftStart: string, requestedStartTime: string): Promise<GetManualTaskSuggestedWorkQueryResponse> {
        const params = {
            departmentWeekId: weekId,
            locationId: locationId,
            taskTypeId: taskTypeId,
            shiftStartTime: shiftStart,
            requestedStartTime: requestedStartTime,
        } as any;

        const response = await AppHttp.get(
            `PlannedAdHocTasks/LongRunning/SuggestedWork?${new URLSearchParams(params).toString()}`
        );

        const data = response ? response.data : null;
        return data;
    }

    public async planWork(weekId: string, plannedLocationId: string, taskTypeId: string, startTime: Dayjs, initialStartTime: Dayjs, duration: number) {
        const command: ManualPlanWorkCommand = {
            _type: 'ManualPlanWorkCommand',
            weekId: weekId,
            plannedLocationId: plannedLocationId,
            taskTypeId: taskTypeId,
            startTime: startTime.toDate(),
            initialStartTime: initialStartTime.toDate(),
            duration: duration,
        };

        await AppHttp.post(`PlannedAdHocTasks/LongRunning/PlanWork`, command);
    }
}

export const PlannedAdhocWeekTasks = new PlannedAdHocWeekTasks();
export default new PlannedAdHocTasks();
