var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('timeline',{attrs:{"id":_vm.rowId,"slots-per-column":_vm.slotsPerColumn,"times":_vm.times,"tasks":_vm.tasksInShift,"occupied-times":_vm.occupiedTimesInShift,"independent-tasks":_vm.independentTasks,"disable-overlap":_vm.disableOverlap,"always-show-tasks":_vm.alwaysShowTasks,"highlight":_vm.highlight,"disable":_vm.disable,"noresize":!_vm.allowActions || _vm.disableResizing,"nomove":!_vm.allowActions || _vm.disableResizing,"allow-task-select":true,"selected-task-id":_vm.selectedTaskId,"allow-stack":_vm.allowStack,"stack":_vm.stack,"is-striped":_vm.isStriped,"start-time":_vm.startTime,"end-time":_vm.endTime,"steps-per-timeslot":1,"show-above-overlay":false,"show-occupied-time":true,"eos-span":_vm.eosSpan,"sos-spans":_vm.sosSpans,"extraEoSSpans":_vm.extraEoSSpans,"extraSoSSpans":_vm.extraSoSSpans,"end-of-shift-task":_vm.endOfShiftTask,"allow-cell-interactions":_vm.allowCellInteractions,"allow-cell-drops":false},on:{"timeSlotClick":_vm.onTimeSlotClick,"taskClick":_vm.onTaskClick,"resized":_vm.onResizeComplete,"moved":_vm.onMoveComplete},scopedSlots:_vm._u([{key:"beforeTimeline",fn:function(){return [_vm._t("beforeTimeline")]},proxy:true},{key:"timeline-overlay",fn:function(){return [_vm._t("timeline-overlay")]},proxy:true},{key:"block-detail",fn:function(ref){
var task = ref.task;
return [_vm._t("taskname",function(){return [(task.errors.length === 0 && task.warnings.length === 0)?_c('v-chip',[_vm._v(_vm._s(_vm.GetTaskName(task)))]):[(task.warnings && task.warnings.length > 0)?_c('v-chip',{staticClass:"resizable pa-1",attrs:{"color":"warning"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_c('pre',{staticClass:"task-error-text"},[_vm._v(_vm._s(task.warnings.map(function (w){ return w.message; }).join('\n')))])],1):_vm._e(),(task.errors && task.errors.length > 0)?_c('v-chip',{staticClass:"resizable pa-1",attrs:{"color":"error"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-circle")]),_c('pre',{staticClass:"task-error-text"},[_vm._v(_vm._s(task.errors.map(function (e){ return e.message; }).join('\n')))])],1):_vm._e()]]},null,{ task: task })]}},{key:"block-content",fn:function(ref){
var task = ref.task;
var width = ref.width;
var isHighlighted = ref.isHighlighted;
var isSelected = ref.isSelected;
return [_c('shift-task-display',{ref:'task-' + task.id,attrs:{"task":task,"width":width,"has-locked-start":task.startTime.isBefore(_vm.startTime) && task.endTime.isAfter(_vm.startTime),"has-locked-end":task.endTime.isAfter(_vm.endTime) && task.startTime.isBefore(_vm.endTime),"isHighlighted":isHighlighted,"isSelected":isSelected}},[_vm._t("taskContents",function(){return [_c('task-warning',{attrs:{"task-has-warnings":task.warnings.length > 0,"color":"yellow"}}),_c('task-error',{attrs:{"task-has-errors":task.errors.length > 0}}),(task.isForPreviousPlan)?_c('v-icon',[_vm._v("mdi-flag-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.GetTaskDisplayText(task))+" ")]},null,{ task: task, width: width })],2)]}},(_vm.allowActions)?{key:"block-actions",fn:function(ref){
var task = ref.task;
return [_vm._t("taskActions",function(){return [_c('shift-task-actions',{attrs:{"begins-before-current-time":_vm.beginsBeforeCurrentTime(task),"is-completed":_vm.isCompleted(task),"is-in-past":_vm.isPast(task),"rowId":_vm.rowId,"task":task,"banned-actions":_vm.bannedTaskActions},on:{"actionTriggered":_vm.onActionTriggered}})]},null,{ task: task })]}}:null,{key:"afterTimeline",fn:function(){return [_vm._t("afterTimeline"),_c('confirm',{ref:"confirm"})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }