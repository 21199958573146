import AppHttp from '../AppHttp';

export class EquipmentDowntime {
    public resource: string = "EquipmentDowntime";

    async ExportShiftDowntime(shiftId: string) {
        const response = await AppHttp.get(`EquipmentDowntime/Shifts/${shiftId}/Csv`);
        const data = response ? response.data : null;
        return data;
    }

    async ExportDowntimeByTimeRange(departmentId: string, rangeStartTime: string | null, rangeEndTime: string | null) {
        let query = '';
        const queryParameters = [rangeStartTime != null ? `fromTime=${rangeStartTime}` : null, rangeEndTime != null ? `toTime=${rangeEndTime}` : null];

        if(queryParameters.filter(qp=>qp!=null).length > 0){
            query = queryParameters.filter(qp=>qp!=null).join("&");
            query = `?${query}`;
        }

        const response = await AppHttp.get(`EquipmentDowntime/Departments/${departmentId}/Csv${query}`);

        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentDowntime();