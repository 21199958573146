import AppHttp from '@/lib/AppHttp';
import ResourceHttp from '@/lib/ResourceHttp';
import {
    CreateEquipmentDowntimeCodeCommand,
    EquipmentDowntimeCodeViewModel, ReorderEquipmentDowntimeCodesCommand,
    UpdateEquipmentDowntimeCodeCommand
} from '@/models/api';

export class EquipmentDowntimeCodes {
    private resource: string = 'EquipmentDowntimeCodes';


    public async getCodes(departmentId: string): Promise<EquipmentDowntimeCodeViewModel[]> {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}`);
        const data = response ? response.data?.equipmentDowntimeCodes : [];
        return data;
    }

    public async updateCode(departmentId: string, command: UpdateEquipmentDowntimeCodeCommand) {
        const response = await AppHttp.post(`Departments/${departmentId}/${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async createCode(departmentId: string, command: CreateEquipmentDowntimeCodeCommand) {
        const response = await AppHttp.post(`Departments/${departmentId}/${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async archiveCode(departmentId: string, id: string) {
        const response = await AppHttp.put(`Departments/${departmentId}/${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreCodeFromArchive(departmentId: string, id: string) {
        const response = await AppHttp.put(`Departments/${departmentId}/${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async exportCodes(departmentId: string) {
        const response = await AppHttp.get(`Departments/${departmentId}/${this.resource}/export`);
        const data = response ? response.data : null;
        return data;
    }

    public async importCodes(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`Departments/${departmentId}/${this.resource}/import`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async reorderCodes(departmentId: string, command: ReorderEquipmentDowntimeCodesCommand) {
        const response = await AppHttp.post(`Departments/${departmentId}/${this.resource}/Reorder`, command);
        const data = response ? response.data : null;
        return data;
    }
}

export default new EquipmentDowntimeCodes();