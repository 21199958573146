import { defineStore } from 'pinia';
import {
    AssignShiftOwnerActionType,
    TaskTypeViewModel,
    Location,
    PropertyDefinitionModel
} from '@/models/api';
import TaskTypes from '@/lib/data/TaskTypes';
import Locations from '@/lib/data/Locations';
import Departments from '../data/Departments';

export const useDepartmentStore = defineStore('department', {
    state: () => {
        return {
            isInitialized: false,
            shiftOwnerId: null as string | null,
            _departmentId: '' as string,
            departmentName: '' as string,
            allowDevelopmentManualTasks: false as boolean,
            locationAbbreviation: '' as string,
            locationName: '' as string,
            endOfShiftTaskType: '' as string | null,
            taskTypes: [] as TaskTypeViewModel[],
            locations: [] as Location[],
            extendedProperties: [] as PropertyDefinitionModel[],
        };
    },
    getters: {
        departmentId(): string {
            if (this.isInitialized === false) {
                throw new Error('Department store is not initialized');
            }

            if (this._departmentId === '') {
                throw new Error('Department Id is empty and has not been set correctly');
            }

            return this._departmentId;
        },
    },
    actions: {
        async initializeStore(departmentId: string)
        {
            if(this._departmentId === departmentId) return;

            this._departmentId = departmentId;
            await Promise.all([
                this.getShiftOwner(),
                this.populateTaskTypes(),
                this.populateLocations(),
                this.populateDepartmentDetails(),
                this.populateExtendedProperties(),
            ]);
            this.isInitialized = true;
        },
        async populateDepartmentDetails() {
            const result = await Departments.getById(this._departmentId);
            this.departmentName = result.name ?? '';
            this.locationName = result.locationName ?? 'TEST';
            this.locationAbbreviation = result.locationAbbrName ?? 'TEST';
            this.endOfShiftTaskType = result.endOfShiftTaskType ?? null;
            this.allowDevelopmentManualTasks = result.allowDevelopmentManualTasks ?? false;
        },
        async populateTaskTypes() {
            if (this._departmentId === null) throw new Error('Department store error: Department Id has not been set');

            const taskTypes = await TaskTypes.getByDepartment(this._departmentId);

            this.taskTypes = taskTypes.map(tt=>({
                ...tt,
                _type: 'TaskTypeViewModel',
                durationMinutes: tt.defaultTimeMinutes,
                durationUnits: tt.defaultTimeMinutes / 15
            } as TaskTypeViewModel)).sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
        },
        async populateLocations() {
            if (this._departmentId === null) throw new Error('Department store error: Department Id has not been set');

            const locations = await Locations.getByDepartment(this._departmentId);
            this.locations = locations.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
        },
        async populateExtendedProperties(){
            if(this._departmentId === null) throw new Error('Department store error: Department Id has not been set');

            this.extendedProperties = await Departments.GetPropertyDefinitions(this._departmentId, true);
        },
        async getShiftOwner() {
            const queryResponse = await Departments.getShiftOwner(this._departmentId);
            this.shiftOwnerId = queryResponse.shiftOwnerId ?? null;
        },
        async setShiftOwner(actionType: AssignShiftOwnerActionType, selectedUserId: string) {
            await Departments.setShiftOwner({
                _type: 'AssignShiftOwnerCommand',
                departmentId: this._departmentId,
                userId: selectedUserId,
                actionType: actionType,
            });

            this.shiftOwnerId = selectedUserId;
        },
    },
});
