<template>
    <shift-loader
        name='shift'
        :isLoading='!dataLoaded'
        @loaded='loadData'
    >
        <div style='width: 100%; align-self: baseline;' class='mt-6 pr-15 pl-15'
             :class="{ 'edit-mode': editing, 'read-mode': !editing }">
            <v-tabs
                color='deep-purple accent-4'
            >
                <v-tab key='equipmentDowntime'>Equipment Downtime</v-tab>
                <v-tab key='actualsEntry'>Plod Entry</v-tab>
                <v-tab key='highLevelActuals'>High Level Physicals</v-tab>

                <v-chip
                    v-if='!editing'
                    class='ma-2 status-chips'
                    text-color='white'
                    color='secondary'
                >
                    Read Only
                </v-chip>
                <v-chip
                    v-if='editing'
                    class='ma-2 status-chips'
                    text-color='white'
                    color='green'
                >
                    Editing
                </v-chip>

                <v-spacer />

                <div class='align-self-center'>
                    <v-btn
                        color='primary'
                        @click='showExportToCsvModal = true'
                        class='ml-2 '>
                        Export to CSV
                    </v-btn>

                    <v-btn
                        v-if='!editing'
                        @click='toggleMode()'
                        color='primary'
                        class='ml-2 '>
                        Enter Edit Mode
                    </v-btn>

                    <v-btn
                        v-if='editing'
                        @click='toggleMode()'
                        color='primary'
                        class='ml-2 '>
                        Save And Exit
                    </v-btn>
                </div>

                <v-tab-item>
                    <equipment-downtime
                        ref='childEquipmentDowntime'
                        v-if='dataLoaded'
                        :shift-id='shiftId'
                        :editing='editing'
                    />
                </v-tab-item>

                <v-tab-item>
                    <plod-entry
                        ref='childPlodEntry'
                        v-if='dataLoaded'
                        :editing='editing'
                    />
                </v-tab-item>

                <v-tab-item>
                    <supervisor-actuals
                        ref='childSupervisorActuals'
                        v-if='dataLoaded'
                        :shift-id='shiftId'
                        :locations='locations'
                        :shift-predictions='shiftPredictions'
                        :supervisor-shift-report='supervisorShiftReport'
                        :editing='editing'
                    />
                </v-tab-item>


            </v-tabs>

        </div>
        <standard-dialog :render='true' :value='showExportToCsvModal' :max-width='500' persistent>
            <template v-slot:title>
                Export to CSV
            </template>
            <template v-slot:content>

                <v-form ref='form' :lazy-validation='true'>
                    <v-radio-group
                        v-model='csvExportOption'
                        :rules='[$rules.required()]'
                    >
                        <v-radio label='Export data for current shift (not implemented)' value='currentShift'
                                 class='mb-5'></v-radio>
                        <v-radio label='Export data by date range' value='dateRange'></v-radio>
                    </v-radio-group>

                    <v-row v-if="csvExportOption === 'dateRange'" class='ml-8'>
                        <v-text-field
                            v-model='csvExportDateRangeStart'
                            label='Start Date'
                            type='date'
                            :rules='[$rules.required()]'
                            class='mr-5'
                        ></v-text-field>
                        <v-text-field
                            v-model='csvExportDateRangeEnd'
                            label='End Date'
                            type='date'
                            :rules='[$rules.required()]'
                            class='mr-10'
                        ></v-text-field>
                    </v-row>
                </v-form>

                <div class='ml-8' style='width: 200px;'>

                </div>

            </template>
            <template v-slot:actions>
                <v-spacer />
                <v-btn color='gray' text @click='showExportToCsvModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='downloadCSV()'>Download</v-btn>
            </template>
        </standard-dialog>

    </shift-loader>
</template>

<script>
import Actuals from '@/lib/data/Actuals';
import { useSystemStore } from '@/lib/stores/SystemStore';
import ShiftLoader from '@/components/ShiftBoard/ShiftLoader.vue';
import { mapState } from 'pinia';
import { useShiftDetails } from '@/lib/stores/Shift';
import { useDepartmentStore } from '@/lib/stores/DepartmentStore';
import { useShiftHighLevelActuals } from '@/lib/stores/Shift/ShiftHighLevelActualsStore';
import { EventBus, Events } from '@/lib/EventBus';
import DownloadHelper from '@/lib/DownloadHelper';
import SupervisorActuals from '@/components/Actuals/SupervisorActuals.vue';
import PlodEntry from '@/components/Actuals/PlodEntry.vue';
import EquipmentDowntime from '@/components/Actuals/EquipmentDowntime.vue';

export default {
    components: { EquipmentDowntime, PlodEntry, SupervisorActuals, ShiftLoader },
    computed: {
        ...mapState(useDepartmentStore, ['departmentId', 'locations', 'locationName', 'taskTypes']),
        ...mapState(useShiftDetails, ['shiftId', 'shiftName', 'shiftStartTime', 'supervisorShiftReport']),
        ...mapState(useSystemStore, ['staffMembers']),
        ...mapState(useShiftHighLevelActuals, ['shiftPredictions', 'shiftDowntime'])
    },
    data() {
        return {
            heading: 'Actuals Entry',
            editing: true,
            dataLoaded: false,
            showExportToCsvModal: false,
            csvExportOption: null,
            csvExportDateRangeStart: null,
            csvExportDateRangeEnd: null
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
        async toggleMode() {
            if (this.editing) {
                let highLevelPhysicalsErrored = false;
                let plodEntryErrored = false;
                let equipmentDowntimeErrored = false;

                let errored = [];

                if (this.$refs.childSupervisorActuals && !(await this.$refs.childSupervisorActuals.save()))
                    errored.push('High Level Physicals');

                if (this.$refs.childPlodEntry && !(await this.$refs.childPlodEntry.save()))
                    errored.push('Plod Entry');

                if (this.$refs.childEquipmentDowntime && !(await this.$refs.childEquipmentDowntime.save()))
                    errored.push('Equipment Downtime');

                if (errored.length > 0) {
                    EventBus.$emit(Events.ToastError, `Error has occured with saving: ${errored.join(', ')}. Please try again.`);
                } else
                    this.editing = false;
            } else {
                this.editing = true;
            }
        },
        loadData() {
            this.dataLoaded = true;
        },
        async downloadCSV(filename = 'export.csv') {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.csvExportOption === 'currentShift') {
                // TODO: Implement export CSV for current shift
            } else {
                let response = await Actuals.downloadCsv(this.departmentId, this.csvExportDateRangeStart, this.csvExportDateRangeEnd);

                let blob = new Blob([response.data], { type: 'text/csv' });
                DownloadHelper.download(`ActualsReport_${this.csvExportDateRangeStart}_${this.csvExportDateRangeEnd}.csv`, blob);
            }

            this.showExportToCsvModal = false;
        }
    }
};
</script>

<style scoped>
.status-chips {
    font-weight: bold;
}
</style>