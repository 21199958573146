<template>
    <div class='pa-5'>
        <v-card  v-if="departments.length > 0">
            <v-card-title>
                <h1 class='title'>Actuals Definitions</h1>
                <template v-if='selectedDepartment'>
                    <v-btn small color='secondary' class='ml-3' @click='create()'>
                        <v-icon left>mdi-plus</v-icon>
                        Add
                    </v-btn>
                    <v-menu
                        v-model='importDialog'
                        max-width='310px'
                        :close-on-content-click='false'
                        v-if='!!selectedDepartment'
                    >
                        <template v-slot:activator='{ on }'>
                            <v-btn v-on='on' small class='ml-1' color='secondary'>
                                <v-icon left>mdi-upload</v-icon>
                                Import
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-text>
                                <input ref='inputUpload' type='file' accept='text/json' @change='getFile($event)' />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color='secondary' @click='importData' :disabled='!file'>Upload</v-btn>
                                <v-btn text @click='importDialog = false'>cancel</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                    <v-btn
                        small
                        class='ml-1'
                        color='gray'
                        v-if='!!selectedDepartment && data.length'
                        @click='exportData'
                    >
                        <v-icon left>mdi-download</v-icon>
                        Export
                    </v-btn>
                </template>
                <v-spacer></v-spacer>
                <v-select
                    v-if='departments'
                    :items='departments'
                    item-value='id'
                    item-text='name'
                    v-model='selectedDepartment'
                    label='Department'
                    single-line
                    hide-details
                    class='admin-table-filter'
                    :menu-props='{ dark: true }'
                >
                    <template v-slot:item='data'>
                        <template v-if='data.item.isGroup'>
                            <v-list-item-content
                                class='caption'
                                @click='$event.stopPropagation()'
                                v-text='data.item.name'
                            ></v-list-item-content>
                        </template>
                        <template v-else>
                            <v-list-item-content class='pl-5' v-text='data.item.name'></v-list-item-content>
                        </template>
                    </template>
                </v-select>
            </v-card-title>
            <v-data-table disable-pagination hide-default-footer :headers='headers' :items='data' v-if='!!selectedDepartment'>
                <template v-slot:item='{ item }'>
                    <tr>
                        <td>{{ item.groupDefinitionName }}</td>
                        <td>{{ item.groupDefinitionType }}</td>
                        <td>
                            <ul class='mt-4 mb-4' v-if='item.associatedGroups'>
                                <li v-for='(group, index) in item.associatedGroups' :key='index'>
                                    {{ group.groupName }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <div class='no-wrap'>
                                <v-btn text icon @click='edit(item, false)'>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </div>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <empty-state
                v-if="!selectedDepartment"
                icon="mdi-alert-circle-outline"
                :message="'Select a ' + $termSync('department')"
            ></empty-state>
        </v-card>
        <standard-dialog :render='!!selected' :value='showModal' :max-width='700' persistent>
            <template v-slot:title>
                <span v-if='selected.id'>Edit</span>
                <span v-else>Create</span>
                <span class='ml-1'>{{ 'Actuals Definition' | term }}</span>
            </template>
            <template v-slot:content>

                <v-form ref='form' :lazy-validation='true'>
                    <v-container fluid>
                        <v-row v-if='selected.id'>
                            <v-col cols='12'>
                                <v-text-field
                                    label='Id'
                                    v-model='selected.id'
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Name'
                                    :rules='[$rules.required(), $rules.maxLength(50)]'
                                    v-model='selected.name'
                                    counter='50'
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                <v-select
                                    v-model='selected.type'
                                    :items='Object.keys(ActualsDefinitionsDataTypes).filter((key) => isNaN(Number(key)))'
                                    label='Field Type'
                                    :rules='[$rules.required()]'
                                    :disabled='disableType'
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="selected.type === 'Formula'">
                            <v-col cols='12'>
                                <v-text-field
                                    autocomplete='off'
                                    label='Formula'
                                    hint='Eg. [Drill Length] * [Count] + 15'
                                    :rules='[$rules.required()]'
                                    v-model='selected.formula'
                                    persistent-hint
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row
                            v-if="selected.type === 'Formula' || selected.type === 'Decimal' || selected.type === 'Integer'">
                            <v-col cols='3'>
                                <v-checkbox
                                    label='Summable'
                                    v-model='selected.summable'
                                    hide-details></v-checkbox>
                            </v-col>
                            <v-col cols='3' v-if="selected.type === 'Formula' || selected.type === 'Decimal'">
                                <v-select
                                    label='Decimal Places'
                                    :items='decimalOptions'
                                    v-model='selected.decimalPlaces'
                                ></v-select>
                            </v-col>
                        </v-row>
                      <template v-if="isValueSetAllowed(selected.type)">
                        <v-row :key="'valueSetRow'">
                          <v-col cols='12'>
                            <v-select
                                v-model='selectedValueSet'
                                :items="['-', 'Material Destinations', 'Material Types', 'Stockpiles', 'Custom']"
                                label='Value Set'
                                @change="valueSetChanged"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row :key="'customValueSetRow'" v-if="selectedValueSet === 'Custom'">
                          <v-col cols='12'>
                            <v-text-field
                                autocomplete='off'
                                label='Custom Value Set'
                                hint='Pipe delimited list of values (eg. OptionA|OptionB|OptionC)'
                                :rules="[
                                    rules.required(),
                                    rules.custom(() => checkValuesAgainstDataType(selected.customValueSet), 'Value set must match with selected data type')
                                  ]"
                                v-model='selected.customValueSet'
                                persistent-hint
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>

                        <v-row class='mt-10'>
                            <v-select
                                v-model='selected.groupIds'
                                :items='definitionGroups'
                                item-text='groupName'
                                item-value='groupId'
                                chips
                                label='Assigned Definition Groups'
                                multiple
                                outlined
                            ></v-select>
                        </v-row>

                    </v-container>
                </v-form>
                <v-alert
                    v-if='!selected.canAlterOrDelete && !selected.loadingCanAlterOrDelete'
                    color='warning'
                    class="white--text">
                    This definition is in use and cannot be altered or deleted.
                </v-alert>
            </template>
            <template v-slot:actions>
                <v-btn color='red' text v-if='selected && selected.id' @click='del()' :disabled='!selected.canAlterOrDelete' :loading='selected.loadingCanAlterOrDelete'>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <v-btn color='primary' text @click='save()' :disabled='!selected.canAlterOrDelete' :loading='selected.loadingCanAlterOrDelete'>Save</v-btn>
            </template>
            <template v-slot:offline-actions>
                <v-spacer></v-spacer>
                <v-btn color='gray' text @click='showModal = false'>Cancel</v-btn>
                <offline-btn text></offline-btn>
            </template>
        </standard-dialog>
        <confirm ref='confirm'></confirm>
    </div>
</template>
<script>
import { EventBus, Events } from '@/lib/EventBus';
import EquipmentRoles from '@/lib/data/EquipmentRoles';
import DownloadHelper from '@/lib/DownloadHelper';
import Actuals from '@/lib/data/Actuals';
import { mapState } from 'pinia';
import { ActualsDefinitionsDataTypes, UpsertActualsDefinitionCommand } from '@/models/api';
import MineAreas from '@/lib/data/MineAreas';
import Validation from '@/lib/Validation';
import validation from "@/plugins/validation";

export default {
    data() {
        return {
            rules: Validation,
            data: [],
            showModal: false,
            departments: [],
            selectedDepartment: null,
            selectedValueSet: null,
            selected: {},
            importDialog: false,
            file: null,
            definitionGroups: null,
            headers: [
                { text: 'Definition Name', value: 'name', width: '30%' },
                { text: 'Type', value: 'type', width: '25%' },
                { text: 'Assigned Groups', value: 'name', width: '43%' },
                { text: '', value: 'id', width: '2%', align: 'right' }
            ],
            decimalOptions: [1, 2],
            disableType: false
        };
    },
    async created() {
        await this.loadDepartments();
    },
    destroyed() {
    },
    methods: {
      valueSetChanged() {
        if (this.selectedValueSet !== 'Custom' && this.selectedValueSet !== '-') {
          this.selected.type = 'Text';
          this.disableType = true;
        }
        else {
          this.disableType = false;
        }
      },
      isValueSetAllowed(type) {
        return type === 'Integer' || type === 'Decimal' || type === 'Text' || type === 'Time';
      },
      isValidInteger(value) {
        const regex = new RegExp(`^-?\\d+$`);
        return regex.test(value);
      },
      isValidDecimal(value, decimalPlaces) {
        const regex = new RegExp(`^-?\\d+\\.\\d{${decimalPlaces}}$`);        
        return regex.test(value);
      },
      isValidHhMm(value) {
        return /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
      },
      checkValuesAgainstDataType(valueSet) {
        if (valueSet) {
          const values = valueSet.split('|');
          const dataType = this.selected.type;
          
          for (let x = 0; x < values.length; x++) {
            let value = values[x];
            
            let validationResult = false;
            
            if (dataType === 'Integer')
              validationResult = !this.isValidInteger(value);
            else if (dataType === 'Decimal')
              validationResult = !this.isValidDecimal(value, this.selected.decimalPlaces);
            else if (dataType === 'Time')
              validationResult = !this.isValidHhMm(value);

            if (validationResult === true)
              return true;
          }
        }
        
        return false;
      },
        async loadDepartments() {
            const mineAreas = await MineAreas.get(['Departments']);

            var departments = [];
            for (let mine of mineAreas) {
                departments.push({ id: mine.id, name: mine.name ?? '', isGroup: true, mineAreaId: mine.id });
                for (let dept of mine.departments) {
                    departments.push({
                        id: dept.id,
                        mineAreaId: mine.id,
                        name: dept.name ?? '',
                        isGroup: false,
                    });
                }
            }
            this.departments = departments;
        },
        async loadDepartmentDefinitions() {
            if(this.selectedDepartment == null)
                return;

            var data = (await Actuals.getDefinitions(this.selectedDepartment)).sort((a, b) => a.groupDefinitionName.localeCompare(b.groupDefinitionName));
            this.definitionGroups = (await Actuals.getGroups(this.selectedDepartment, [])).sort((a, b) => a.groupName.localeCompare(b.groupName));

            this.data = data;
        },
        create() {
            var displayOrder = this.data.length ? this.data[this.data.length - 1].displayOrder + 1 : 1;
            this.edit({
                decimalPlaces: 1,
            }, true);
        },
        edit(item, isNew) {
            this.selected = {
                id: item.groupDefinitionId,
                name: item.groupDefinitionName,
                type: item.groupDefinitionType,
                formula: item.formula,
                summable: item.summable,
                groupIds: item.associatedGroups ? item.associatedGroups.map(x => x.groupId) : [],
                decimalPlaces: item.decimalPlaces,
                loadingCanAlterOrDelete: !isNew,
                canAlterOrDelete: isNew
            };
            
            if (item.customValueSet) {
              this.selected.customValueSet = item.customValueSet;
              this.selectedValueSet = 'Custom';
            }
            else if (item.valueSetName === 'MaterialDestinations')
              this.selectedValueSet = 'Material Destinations'
            else if (item.valueSetName === 'MaterialTypes')
              this.selectedValueSet = 'Material Types'
            else if (item.valueSetName === 'Stockpiles')
              this.selectedValueSet = 'Stockpiles'
            else
              this.selectedValueSet = '-';
            
            this.valueSetChanged();

            if(!isNew){
                this.selected.loadingCanAlterOrDelete = true;
                Actuals.definitionHasData(this.selectedDepartment, this.selected.id).then(response => {
                    if(this.selected?.id === item.groupDefinitionId)
                        this.selected.canAlterOrDelete = response.hasData === false;
                }).finally(() => {
                    if(this.selected?.id === item.groupDefinitionId)
                        this.selected.loadingCanAlterOrDelete = false;
                });
            }
            
            this.showModal = true;
            setTimeout(() => this.$refs.form.resetValidation(), 1);
        },
        async del() {
            if (await this.$refs.confirm.openAsDeleteResource(this.$termSync('Actuals Definition'), {})) {
                await Actuals.deleteDefinition(this.selectedDepartment, this.selected.id);
                EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Definition')} Deleted`);
                this.showModal = false;
                await this.loadDepartmentDefinitions();
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.selected.type !== 'Formula' && this.selected.type !== 'Decimal' && this.selected.type !== 'Integer')
                this.selected.summable = null;

            if (this.selected.type !== 'Formula' && this.selected.type !== 'Decimal')
                this.selected.decimalPlaces = null;

            if (this.selected.type !== 'Formula')
                this.selected.formula = null;
            
            let command = {
              ...this.selected,
              departmentId: this.selectedDepartment,
              customValueSet: null,
              valueSet: null
            };
            
            if (this.selectedValueSet === 'Custom') {
              command.customValueSet = this.selected.customValueSet;
            } else if (this.selectedValueSet !== '-') {
              command.valueSet = this.selectedValueSet.replace(' ', '');
            }

            await Actuals.upsertDefinition(command);

            EventBus.$emit(Events.ToastSuccess, `${this.$termSync('Actuals Definition')} Saved`);
            this.showModal = false;
            await this.loadDepartmentDefinitions();
        },
        async exportData() {
            const result = await Actuals.exportDefinitions(this.selectedDepartment);
            var csv = result.csvFile;
            var blob = DownloadHelper.makeBlobFromFileString(csv);
            var departmentName = this.selectedDepartmentModel?.name?.replace(/[/\\?%*:|"<>]/g, '') ?? "Unknown_Department";
            DownloadHelper.download(`ActualsDefinitions-${departmentName}.csv`, blob);
        },
        async importData() {
            try {
                this.$wait.start('saving');
                this.uploadResult = null;
                await Actuals.importDefinitions(this.selectedDepartment, this.file);
                this.importDialog = false;
                await this.loadDepartmentDefinitions();
            } finally {
                this.$wait.end('saving');
            }
        },
        async getFile(e) {
            this.file = e.target.files[0];
        }
    },
    computed: {
        ActualsDefinitionsDataTypes() {
            return ActualsDefinitionsDataTypes;
        },
        selectedDepartmentModel() {
            return this.departments.find(d => d.id === this.selectedDepartment) ?? null;
        },
    },
    watch: {
        async selectedDepartment(newValue){
            await this.loadDepartmentDefinitions();
        }
    }
};
</script>
<style lang='scss' scoped></style>
